
.locale {
    text-transform: uppercase;
    align-self: center;
    margin-right: 12px;
}

.fields{

    > * {

        &::v-deep(.v-input__append){
            margin-inline-start: 0px;
        }

        &:not(:last-child):not(:only-child){
            &::v-deep(.v-field) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }


        &:not(:first-child):not(:only-child){
            &::v-deep(.v-field) {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        &::v-deep(.v-text-field__suffix){
            display: none;
        }

    }
}
