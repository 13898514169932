
@import '~styles/_var_app.scss';


.drawer-left{

    .button-burger{
        position: absolute;
        right: 0;
        margin: 0 15px;
    }

    .wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left:$padding-x;
        padding-right:$padding-x;
        min-height: calc(100% - ($header-height + 1px));
        opacity: 1;
        transition: opacity $t-duration ease $t-duration * 0.5;

        > *{
            width: 100%;
        }

    }

    .logo{
        height: $header-height;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid $c-border;
        background-color: white;
        z-index: 1;

        position: sticky;
        top: 0;
        img{
            height: 18px;
        }
    }

    .undertaker{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 50px 0;

        .avatar{
            border: 1px solid $c-border;
            width: 80px;
            height: 80px;
            margin-bottom: 20px;
            border-radius: 8px;

            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .name{

        }
    }

    .menu-primary{
        margin-bottom: 37px;
        font-weight: bold;
    }

    .menu-secondary{
        margin-top: auto;
        margin-bottom: $padding-x;

        .button{
            text-align: center;
            width: 100%;

            &:not(:last-child):not(:only-child){
                margin-bottom: 10px;
            }
        }

        .link{
            text-align: center;
            width: 100%;
            margin-top: 8px;
            font-weight: normal;
            text-decoration: underline;
            font-size: 14px;
        }
    }
}
