@import "../pure-icons";
@import "./var_app";
@import "./transitions";
@import "./vuetify_components";

@import url('https://fonts.googleapis.com/css2?family=Oooh+Baby&display=swap');

@font-face {
    font-family: 'Manier';
    src:    url('../../fonts/manier-medium.eot');
    src:    url('../../fonts/manier-medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/manier-medium.woff2') format('woff2'),
    url('../../fonts/manier-medium.woff') format('woff'),
    url('../../fonts/manier-medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Manier';
    src:    url('../../fonts/manier-heavy.eot');
    src:    url('../../fonts/manier-heavy.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/manier-heavy.woff2') format('woff2'),
    url('../../fonts/manier-heavy.woff') format('woff'),
    url('../../fonts/manier-heavy.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Manier';
    src:    url('../../fonts/manier-light.eot');
    src:    url('../../fonts/manier-light.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/manier-light.woff2') format('woff2'),
    url('../../fonts/manier-light.woff') format('woff'),
    url('../../fonts/manier-light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}


#preprod-advert {
    position: fixed;
    z-index: 101;
    bottom: 0;
    width: 100%;
    padding: 15px;
    font: {
        family: $f-primary;
        size: 30px;
        weight: 700;
    }
    line-height: 1;
    color: white;
    text-align: center;
    text-transform: uppercase;
    background-color: transparentize(darkred, 0.3);
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.2s;

    animation: blink-2 3s infinite both;
}
@keyframes blink-2 {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}


body{
    font-family: $f-text;
    line-height: normal;
}

.page{
    padding: 0;
}

.page-wrapper{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.page-limiter{
    width: 100%;
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $s-desktop-l) {
        max-width: 900px;
    }
}
.page-limiter-xl{
    width: 100%;
    max-width: 872px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $s-desktop-l) {
        max-width: 1100px;
    }
}

.page-padding{
    padding: 20px;
}

.h1{
    font-family: $f-title;
    font-weight: bold;
    font-size: 25px;
    line-height: normal;
}
.h2{
    font-family: $f-title;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.23;
}
.h3{
    font-family: $f-title;
    font-weight: bold;
    font-size: 16px;
    line-height: normal;
}
.h4{
    font-family: $f-title;
    font-weight: bold;
    font-size: 14px;
    line-height: normal;
}
.h5{
    font-family: $f-title;
    font-weight: bold;
    font-size: 12px;
    line-height: normal;
}

.spacer{
    width: 100%;
    margin-bottom: 30px;
}

.text-small{
    font-size: 12px;
    line-height: normal;
}

.text-notice{
    font-size: 10px;
    line-height: normal;
    font-weight: bold;
    text-transform: uppercase;
}

.notice{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 30px;
    margin-top: 16px;
    margin-bottom: 16px;
    line-height: normal;
    gap: 10px 20px;

    @media (min-width: $s-mobile) {
        flex-direction: row;
        align-items: center;
    }

    .icon{
        height: 50px;
        width: 50px;
        flex: 0 0 auto;

        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

}

mark {
    $color1: $c-second;
    $color2: $c-fourth;
    $gradientStart : 20%;
    $gradientEnd : 80%;

    font-family: $f-highlight;
    font-weight: normal;
    font-size: 1.4em; // The highlighted words are bigger than the regular font
    line-height: 1;
    z-index: -1;

    background: linear-gradient(transparent $gradientStart, $color1 $gradientStart, $color1 $gradientEnd, transparent $gradientEnd);

    &.alt {
        background: linear-gradient(transparent $gradientStart, $color2 $gradientStart, $color2 $gradientEnd, transparent $gradientEnd);
    }
}

.bg-dark{
    background: $bg-dark url('../../images/pattern_dark.jpg');
    color: $c-text-light;
}

.tag{
    display: inline-block;
    padding: 12px 15px;
    border-radius: 20px;
    background-color: $c-second-lighter;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;

    &.tag-neutral{
        background-color: $c-light;
    }

    &.tag-small{
        font-size: 12px;
        padding: 9px 14px;
    }

    &.tag-rounded{
        aspect-ratio: 1;
        border-radius: 100px;
        font-size: 12px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(.tag-neutral){
            color: $c-primary;
        }

        &.tag-small{
            font-size: 10px;
            height: 30px;
            padding: 8px 14px;
        }
    }
}

