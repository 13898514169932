@use "sass:math";

// Break-Point
$s-desktop-xl: 2000px;
$s-desktop-l: 1600px;
$s-desktop: 1200px;
$s-tablet: 1024px;
$s-mobile: 768px;
$s-mobile-md: 600px;
$s-mobile-sm: 425px;
$s-mobile-xs: 375px;

$site-width: 1240px;
$site-width-pad-limited: 100px;
$site-width-limited: $site-width - 2 * $site-width-pad-limited;
$site-width-pad-x: 20px;
$site-width-no-padding: $site-width + (3 * $site-width-pad-x);

$s-nav-burger-limit: $s-tablet;

$header-height-min: 60px;
$header-height-max: 90px;
$header-top-banner-height: 30px;
$wrapper-height-min: calc(100% - #{$header-height-min});
$wrapper-height-max: 100%;
$logo-height-min: 40px;
$logo-width-min: 180px;
$logo-height-max: 60px;
$logo-width-max: 180px;

$section-pad-y-min: 25px;
$section-pad-y-max: 50px;

// Font
// les polices sont importées dans _fonts.scss
$f-title: 'Manier', serif;
$f-primary: $f-title;
$f-text: 'Manier', serif;
$f-highlight: 'Oooh Baby', cursive;
$f-icons: 'pure-icons';
$f-size-base: 16px;
$f-line-height-base: 1.25;

// Color
$c-primary: #C29247;
$c-primary-lighter: #C29247;
$c-second: #db7093;
$c-second-lighter: $c-second;
$c-third: $c-primary;
$c-fourth: $c-second;
$c-text: #101514;

$c-border: #dedede;

$c-gray: #6D6D6D;
$c-gray-light: #fafafa;
$c-white: #ffffff;
$c-light: $c-gray-light;
$c-dark: #101010;
$c-error: #D0021B;
$c-success: #005500;

$c-text-dark: $c-text;
$c-text-light: $c-white;

$bg-primary: $c-primary;
$bg-white: $c-white;
$bg-light: $c-light;
$bg-dark: $c-dark;
$bg-site: $bg-white;
$bg-footer: $bg-light;
$c-dark-bg: $c-dark;
$c-light-bg: $c-light;

// Buttons
$s-absolute-slider-btn: $s-desktop-l;
$link-text-color: $c-primary;
$link-text-color-hover: $c-text;
$btn-text-font-f: $f-text;
$btn-text-font-s: 16px;
$btn-text-color: $c-primary;
$btn-text-color-hover: #fff;
$btn-text-color-secondary: $c-second;
$btn-text-color-secondary-hover: #fff;
$btn-text-color-disabled: #a8a8a8;
$btn-border-width: 0px;
$btn-border-color: $c-primary;
$btn-border-color-hover: $c-primary;
$btn-border-radius: 12px;
$btn-bg-color: $bg-white;
$btn-bg-color-hover: $c-primary;
$btn-bg-color-secondary: $bg-white;
$btn-bg-color-secondary-hover: $c-second;
$btn-bg-color-disabled: #f0f0f0;
$btn-min-h: 40px;
$btn-pad-h: 14px;
$btn-pad-w: 30px;
$btn-slider-sm-size: 35px;
$btn-slider-lg-size: 45px;

// Forms
$form-field-pad-h: 15px;
$form-field-pad-w: 30px;
$form-field-border-width: 0px;
$form-field-border-radius: 10px;
$form-field-border-radius-small: 10px;
$form-field-label-font-f: $f-primary;
$form-field-label-font-s: 12px;
$form-field-text-font-f: $f-primary;
$form-field-text-font-s: 14px;
$form-field-line-height: $f-line-height-base;
// Forms [on light background]
$form-field-bg-color: $bg-white;
$form-field-bg-color-focus: $form-field-bg-color;
$form-field-border-color: $c-border;
$form-field-border-color-hover: transparentize($form-field-border-color, 0.7);
$form-field-text-color: $c-text;
$form-field-placeholder-color: transparentize($c-text, 0.7);
// Forms [on dark background]
$form-field-bg-color-bis: $form-field-bg-color;
$form-field-bg-color-bis-focus: $form-field-bg-color-focus;
$form-field-border-color-bis: $form-field-border-color;
$form-field-border-color-bis-hover: $form-field-border-color-hover;
$form-field-text-color-bis: $form-field-text-color;
$form-field-placeholder-color-bis: $form-field-placeholder-color;

// Burger
$hamburger-padding-y           : 20px;
$hamburger-padding-x           : 0px;
$hamburger-bg-color            : transparent;
$hamburger-layer-width         : 25px;
$hamburger-layer-height        : 2px;
$hamburger-layer-spacing       : 7px; // Bar spacing
$hamburger-layer-color         : $c-light; // Bar color
$hamburger-layer-color-top     : $c-light; // Top bar color
$hamburger-layer-color-bottom  : $c-light; // Bottom bar color
$hamburger-layer-border-radius : 0;  // Bar border radius
$hamburger-hover-opacity       : 1;
$hamburger-active-layer-color  : $c-dark;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;
$hamburger-total-width         : (2 * $hamburger-padding-x) + $hamburger-layer-width;
$hamburger-total-height        : (2 * $hamburger-padding-y) + (3 * $hamburger-layer-height) + (2 * $hamburger-layer-spacing);
$hamburger-margin-right        : ($header-height-min - $hamburger-total-width) * 0.5;

// Transition
$t-duration: 0.3s;

$shadowLarge: 13px 13px 26px 0 rgba(174, 174, 192, 0.2);
$shadowShort: 1.5px 1.5px 5px 0 rgba(174, 174, 192, 0.4);