.slide-enter-active,
.slide-leave-active {
    transition: opacity 0.4s ease, transform 0.4s ease-out;
}
.slide-enter-from {
    opacity: 0;
    transform: translateY(-10px);
}
.slide-leave-to {
    opacity: 0;
    transform: translateY(10px);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.4s ease;
}
.fade-enter-from {
    opacity: 0;
}
.fade-leave-to {
    opacity: 0;
}

.fade-staggering {
    $duration: 0.6s;
    $staggering: 0.025s;
    $offset: 1em;
    $easing: cubic-bezier(.2,.5,.1,1);

    &-leave-active {
        transition: opacity 0s;
    }

    &-enter-active {
        transition: opacity $duration ease;
        transition-delay: calc( $staggering * var(--i) );
    }

    &-enter-from,
    &-leave-to {
        opacity: 0;
    }

    &-enter-from {
        opacity: 0;
    }
    &-leave-to {
        opacity: 0;
    }
}


//.list-enter-active,
//.list-leave-active {
//    transition: opacity 0.3s ease, transform 0.3s ease-out;
//}
//.list-enter-from,
//.list-leave-to {
//    opacity: 0;
//    transform: translateX(30px);
//}

.list-move, // apply transition to moving elements
.list-enter-active,
.list-leave-active {
    transition: opacity 0.6s ease, transform 0.6s ease;
}
.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

.list-leave-active { // ensure leaving items are taken out of layout flow so that moving animations can be calculated correctly.
    position: absolute;
}

.slide-vertical {
    $duration: 0.6s;
    $staggering: 0.025s;
    $offset: 1em;
    $easing: cubic-bezier(.2,.5,.1,1);


    &-leave-active {
        transition: opacity 0s, transform 0s;
        //transition: opacity $duration/2 ease, transform $duration/2 cubic-bezier(.5,0,.7,.4); //cubic-bezier(.7,0,.7,1);
        //transition-delay: calc( $staggering * (var(--total) - var(--i)) );
    }

    &-enter-active {
        transition: opacity $duration ease, transform $duration $easing;
        transition-delay: calc( $staggering * var(--i) );
    }

    &-enter-from,
    &-leave-to {
        opacity: 0;
    }

    &-enter-from {
        transform: translateY(-$offset);
    }
    &-leave-to {
        transform: translateY($offset);
    }
}

.slide-horizontal {
    $duration: 0.6s;
    $staggering: 0.025s;
    $offset: 1em;
    $easing: cubic-bezier(.2,.5,.1,1);

    &-leave-active {
        transition: opacity 0s, transform 0s;
    }

    &-enter-active {
        transition: opacity $duration ease, transform $duration $easing;
        transition-delay: calc( $staggering * var(--i) );
    }

    &-enter-from,
    &-leave-to {
        opacity: 0;
    }

    &-enter-from {
        transform: translateX(-$offset);
    }
    &-leave-to {
        transform: translateX($offset);
    }
}

.toggle-header {
    $duration: 0.6s;
    $offset: $header-height;
    $easing: cubic-bezier(.2,.5,.1,1);

    &-leave-active {
        opacity: 1;
        transition: opacity $duration $easing, margin-top $duration $easing;
    }

    &-enter-active {
        transition: opacity $duration $easing, margin-top $duration $easing;
    }

    &-enter-from,
    &-leave-to {
        opacity: 0;
        margin-top: -$offset;
    }
}
