
@import '~styles/_var_app.scss';

.page-content{
    position: relative; // To maintain the overlay in this container
    flex: 1 0 auto; // To make the container stretch to the bottom of the viewport
    padding: $padding-x * 0.5;

    @media (min-width: $s-tablet) {
        padding: $padding-x;
    }
}
