
@import "~styles/../_pure-icons";

.v-text-field{
    &::v-deep(input) {
        text-transform: uppercase;

        &::-webkit-calendar-picker-indicator {
            opacity: 0;
        }
        &:after {
            position: absolute;
            z-index: -1;

            font-family: 'pure-icons';
            content: map-get($pure-icons-map, 'calendar');
            font-size: 18px;
            right: 20px;
        }
    }
}
