@import "../var";
@import "../pro/var_pro";

$header-height: 70px;
$padding: 16px;
$padding-x: 30px;
$c-border: #ebebeb;

$s-mobile: 768px;
$s-tablet: 1024px; // TODO il ne faut pas redéclarer ça ici, importer les fichier dans les templates à la place

$form-field-bg-color: #fafafa;

$c-alt-primary: #9AD2C9;
$c-alt-primary-lighter: #5FBEB3;
$c-alt-second: #D5ECE8;
$c-alt-second-lighter: #F1F9F7;
