
@import '~styles/_var_app.scss';

.v-file-input {
    display: none;
}

.heading{
    display: flex;
    margin-bottom: 10px;
    gap: 10px;

    .placeholder{
        font-size: 14px;
        margin-right: auto;
    }
}

.button-upload{
    margin-bottom: 12px;
}

.previews{
    $gap: 20px;

    display: inline-block;

    .preview{
        width: 100%;
        position: relative;

        img{
            width: 100%;
        }

        .buttons{
            $gap: 10px;
            position: absolute;
            display: flex;
            flex-wrap: wrap;
            gap: $gap;
            top: $gap;
            right: $gap;
        }
    }

    &:not(.multiple){
        img{
            height: 300px;
            //max-width: 100%;
            //width: auto;
            object-fit: cover;
        }
    }

    &.multiple{
        column-count: 1;
        column-gap: $gap;
        width: 100%;

        @media (min-width: $s-mobile-md) {
            column-count: 2;
        }

        .preview{
            margin-bottom: $gap;
        }
    }
}

.errors{
    font-size: 0.75rem;
    padding-left: 16px;
}

