
@import '~styles/_var_app.scss';

.page-header {
    top: $header-height + 1px; // Override the sticky position

    &.additional-offset{
        top: $header-height + $header-height + 1px;
    }
}

.service-pricing-categories{
    width: 100%;

    .service-pricing-category{


        > .name{
            margin-top: 30px;
            margin-bottom: 20px;
        }

        .service-pricings{

            .service-pricing{
                display: flex;
                align-items: center;
                background-color: #ffffff;
                padding: 10px 20px;
                gap: 10px;

                @media (min-width: $s-mobile) {
                    padding: 10px 30px;
                }


                &:not(:last-child):not(:only-child){
                    margin-bottom: 10px;
                }

                .name{
                    margin-right: auto;
                    word-break: break-word;
                }

                .price{
                    width: 140px;
                }

            }

            .text-notice{
                margin-top: 20px;
            }
        }
    }
}
