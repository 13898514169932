
@import '~styles/_var_app.scss';

.inquiry{
    margin-bottom: 15px;
    padding: 20px 20px;

    @media (min-width: $s-mobile-md) {
        padding: 25px 30px;
    }

    .wrapper{
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;

        @media (min-width: $s-mobile-md) {
            flex-wrap: nowrap;
        }
    }

    .main{
        display: flex;
        gap: 20px;

        @media (min-width: $s-mobile-md) {
            align-items: center;
        }

        .picture{
            //display: none;
            height: 40px;
            width: 40px;
            flex: 0 0 auto;

            @media (min-width: $s-mobile-xs) {
                //display: block;
            }

            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .name{
        }

        .status{
            font-weight: normal;
            font-size: 12px;
        }
    }

    .infos{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;
        margin-left: auto;
        gap: 6px 10px;

        @media (min-width: $s-mobile-md) {
            justify-content: flex-end;
            flex-wrap: nowrap;
            flex: 1 0 auto;
        }

        .date{
            font-size: 14px;
        }
    }
}
