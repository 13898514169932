
@import '~styles/_var_app.scss';

.page-header {
    top: $header-height + 1px; // Override the sticky position

    &.additional-offset{
        top: $header-height + $header-height + 1px;
    }
}

.heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
}

.establishments{
    $gap: 20px;
    display: flex;
    flex-direction: column;
    gap: $gap;
    margin-top: $gap;
    margin-bottom: $gap;

    .establishment{
        background-color: #ffffff;
        padding: 30px;
        padding-right: 15px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border: 1px solid transparent;
        transition: border $t-duration ease;

        @media (min-width: $s-mobile) {
            flex-direction: row;
        }

        &.empty-price{
            border-color: $c-primary;
        }

        .infos{

            .name{
                font-weight: bold;
            }

            .address{

            }

        }

        .buttons{
            display: flex;
            align-items: center;
            gap: 10px;
            flex: 1;

            @media (min-width: $s-mobile) {
                flex: none;
                margin-left: auto;
            }

            .button-edit{
                flex: 1;
            }

        }
    }
}
