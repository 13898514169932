
@import '~styles/_var_app.scss';

.page-header {
    top: $header-height + 1px; // Override the sticky position

    &.additional-offset{
        top: $header-height + $header-height + 1px;
    }
}

.product-category{
    margin-top: 30px;
    margin-bottom: 30px;

    .heading{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .products{
        display: flex;
        flex-direction: column;
        gap: 12px;

        .product{
            padding: 10px 10px;

            .wrapper{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }

            .picture-and-infos{
                display: flex;
                align-items: center;
                gap: 10px 0;
                flex: 1;
                flex-basis: 100%;

                @media (min-width: $s-mobile-sm) {
                    flex-basis: auto;
                }
            }

            .price-and-buttons{
                display: flex;
                align-items: center;
                margin-left: auto;
            }

            .picture{
                flex: none;
                width: 50px;
                height: 50px;
                border-radius: 10px;
                border: solid 1px $c-border;
                overflow: hidden;
                margin-right: 20px;

                > img{
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }

            }
            .name{
                font-weight: bold;
                margin-right: 10px;
            }
            .material{
                text-transform: uppercase;
                font-weight: 300;
                font-size: 14px;
                margin-right: 10px;
            }
            .price{
                margin-left: auto;
                font-weight: bold;
            }

            .buttons{
                display: flex;
                align-items: center;
                margin-left: 10px;
                gap: 10px;
            }

        }
    }
}

