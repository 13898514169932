
@import '~styles/_var_app.scss';

.search{
    flex: 0 1 270px;

    &::v-deep(.v-label) {
        margin-top: 4px;
        white-space: nowrap;
    }
}

.tabs{
    --v-tabs-height: 50px;

    @media (min-width: $s-mobile) {
        --v-tabs-height: 70px;
    }

    .v-tab.v-tab{
        min-width: inherit;
        padding: 0 2px;

        font-size: 14px;

        @media (min-width: $s-mobile) {
            font-size: 16px;
        }
    }
}

.heading{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 6px;
}

.inquiries{

    .inquiry{

        margin-bottom: 15px;

        .wrapper{
            display: flex;
            align-items: center;
            gap: 20px;
        }

        .picture{
            height: 40px;
            width: 40px;
            flex: 0 0 auto;

            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .name{
        }

        .status{
            font-weight: normal;
            font-size: 12px;
        }

        .infos{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;
            flex: 1 0 auto;
            margin-left: auto;
            gap: 6px 10px;
        }
    }
}

.no-result{
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 8px;

    > .v-icon{
        font-size: 50px;
        margin-bottom: 4px;
    }

    .button{
        margin-top: 20px;
    }
}

