
$site-width: 1240px;
$site-width-pad-limited: 100px;
$site-width-limited: $site-width - 2 * $site-width-pad-limited;
$site-width-pad-x: 30px;
$section-pad-y-min: 15px;
$section-pad-y-max: 22px;

$logo-width-min: 55px;
$logo-width-max: 69px;

$c-primary: #d1957c;
$c-second: #f8e4db;
$c-second-lighter: #fbf1ed;
$c-third: #9ad2c9;
$c-fourth: #d5ece8;

$c-light: #fafafa;
$c-dark: #101010;
$bg-dark: $c-primary;

$link-text-color: $c-text;
$link-text-color-hover: $c-primary;
$btn-text-color: $c-white;
$btn-text-color-hover: $c-white;
$btn-text-color-secondary: $c-primary;
$btn-text-color-secondary-hover: $c-primary;
$btn-bg-color: $c-primary;
$btn-bg-color-hover: $c-primary;
$btn-bg-color-secondary: $c-second-lighter;
$btn-bg-color-secondary-hover: $c-second-lighter;