
@import '~styles/_var_app.scss';

.tabs{

    .v-tab.v-tab{
        min-width: inherit;
        padding: 0 2px;

        font-size: 14px;

        @media (min-width: $s-mobile) {
            font-size: 16px;
        }
    }
}
