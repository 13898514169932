$pure-icons-font: "pure-icons";

@font-face {
    font-family: $pure-icons-font;
    src: url("../fonts/pure-icons.svg?88bcd44155c06e557ec1425c0f7d684e#pure-icons") format("svg"),
url("../fonts/pure-icons.ttf?88bcd44155c06e557ec1425c0f7d684e") format("truetype"),
url("../fonts/pure-icons.woff?88bcd44155c06e557ec1425c0f7d684e") format("woff"),
url("../fonts/pure-icons.woff2?88bcd44155c06e557ec1425c0f7d684e") format("woff2");
}

i[class*="icon-"] {
    display: inline-block;
    font-family: pure-icons;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.up {transform: rotate(-90deg);}
    &.down {transform: rotate(90deg);}
    &.left {transform: rotate(180deg);}
    &.icon-l {font-size: 2em;}
    &.icon-xl {font-size: 3em;}
}

$pure-icons-map: (
    "arrow-right": "\41",
    "arrow-left": "\42",
    "arrow-angle-down": "\43",
    "arrow-angle-right": "\4a",
    "arrow-angle-left": "\4b",
    "plus": "\44",
    "cross": "\46",
    "arrow": "\f101",
    "check": "\f102",
    "checkbox-checked": "\f103",
    "checkbox": "\f104",
    "download": "\f105",
    "map-pin": "\f106",
    "minus": "\f107",
    "phone": "\f108",
    "radio-checked": "\f109",
    "radio": "\f10a",
    "search": "\f10b",
    "share": "\f10c",
    "smartphone": "\f10d",
    "social-facebook": "\f10e",
    "social-instagram": "\f10f",
    "social-linkedin": "\f110",
    "social-tiktok": "\f111",
    "social-twitter": "\f112",
    "social-whatsapp": "\f113",
    "social-youtube": "\f114",
    "trash": "\f115",
    "truck": "\f116",
    "user": "\f117",
    "arrow-angle-up": "\f118",
    "burger": "\f119",
    "filter": "\f11a",
    "help": "\f11b",
    "home": "\f11c",
    "email": "\f11d",
    "play": "\f11e",
    "arrow-down": "\f11f",
    "settings": "\f120",
    "link-external": "\f121",
    "eye-closed": "\f122",
    "eye-opened": "\f123",
    "star-empty": "\f124",
    "star-full": "\f125",
    "edit": "\f126",
    "account": "\f127",
    "arrow-angle-double": "\f128",
    "arrow-up": "\f129",
    "bookmark": "\f12a",
    "calendar": "\f12b",
    "camera": "\f12c",
    "chat": "\f12d",
    "computer": "\f12e",
    "copy": "\f12f",
    "cursor": "\f130",
    "euro": "\f131",
    "globe": "\f132",
    "label": "\f133",
    "lock": "\f134",
    "mail": "\f135",
    "picture": "\f136",
    "send": "\f137",
    "stop": "\f138",
    "suitcase": "\f139",
    "time": "\f13a",
    "users": "\f13b",
    "book": "\f13c",
    "chart": "\f13d",
    "save": "\f13e",
    "info": "\f13f",
    "star-half": "\f140",
    "lock_2": "\f141",
    "warning": "\f142",
);

.icon-arrow-right:before {
    content: map-get($pure-icons-map, "arrow-right");
}
.icon-arrow-left:before {
    content: map-get($pure-icons-map, "arrow-left");
}
.icon-arrow-angle-down:before {
    content: map-get($pure-icons-map, "arrow-angle-down");
}
.icon-arrow-angle-right:before {
    content: map-get($pure-icons-map, "arrow-angle-right");
}
.icon-arrow-angle-left:before {
    content: map-get($pure-icons-map, "arrow-angle-left");
}
.icon-plus:before {
    content: map-get($pure-icons-map, "plus");
}
.icon-cross:before {
    content: map-get($pure-icons-map, "cross");
}
.icon-arrow:before {
    content: map-get($pure-icons-map, "arrow");
}
.icon-check:before {
    content: map-get($pure-icons-map, "check");
}
.icon-checkbox-checked:before {
    content: map-get($pure-icons-map, "checkbox-checked");
}
.icon-checkbox:before {
    content: map-get($pure-icons-map, "checkbox");
}
.icon-download:before {
    content: map-get($pure-icons-map, "download");
}
.icon-map-pin:before {
    content: map-get($pure-icons-map, "map-pin");
}
.icon-minus:before {
    content: map-get($pure-icons-map, "minus");
}
.icon-phone:before {
    content: map-get($pure-icons-map, "phone");
}
.icon-radio-checked:before {
    content: map-get($pure-icons-map, "radio-checked");
}
.icon-radio:before {
    content: map-get($pure-icons-map, "radio");
}
.icon-search:before {
    content: map-get($pure-icons-map, "search");
}
.icon-share:before {
    content: map-get($pure-icons-map, "share");
}
.icon-smartphone:before {
    content: map-get($pure-icons-map, "smartphone");
}
.icon-social-facebook:before {
    content: map-get($pure-icons-map, "social-facebook");
}
.icon-social-instagram:before {
    content: map-get($pure-icons-map, "social-instagram");
}
.icon-social-linkedin:before {
    content: map-get($pure-icons-map, "social-linkedin");
}
.icon-social-tiktok:before {
    content: map-get($pure-icons-map, "social-tiktok");
}
.icon-social-twitter:before {
    content: map-get($pure-icons-map, "social-twitter");
}
.icon-social-whatsapp:before {
    content: map-get($pure-icons-map, "social-whatsapp");
}
.icon-social-youtube:before {
    content: map-get($pure-icons-map, "social-youtube");
}
.icon-trash:before {
    content: map-get($pure-icons-map, "trash");
}
.icon-truck:before {
    content: map-get($pure-icons-map, "truck");
}
.icon-user:before {
    content: map-get($pure-icons-map, "user");
}
.icon-arrow-angle-up:before {
    content: map-get($pure-icons-map, "arrow-angle-up");
}
.icon-burger:before {
    content: map-get($pure-icons-map, "burger");
}
.icon-filter:before {
    content: map-get($pure-icons-map, "filter");
}
.icon-help:before {
    content: map-get($pure-icons-map, "help");
}
.icon-home:before {
    content: map-get($pure-icons-map, "home");
}
.icon-email:before {
    content: map-get($pure-icons-map, "email");
}
.icon-play:before {
    content: map-get($pure-icons-map, "play");
}
.icon-arrow-down:before {
    content: map-get($pure-icons-map, "arrow-down");
}
.icon-settings:before {
    content: map-get($pure-icons-map, "settings");
}
.icon-link-external:before {
    content: map-get($pure-icons-map, "link-external");
}
.icon-eye-closed:before {
    content: map-get($pure-icons-map, "eye-closed");
}
.icon-eye-opened:before {
    content: map-get($pure-icons-map, "eye-opened");
}
.icon-star-empty:before {
    content: map-get($pure-icons-map, "star-empty");
}
.icon-star-full:before {
    content: map-get($pure-icons-map, "star-full");
}
.icon-edit:before {
    content: map-get($pure-icons-map, "edit");
}
.icon-account:before {
    content: map-get($pure-icons-map, "account");
}
.icon-arrow-angle-double:before {
    content: map-get($pure-icons-map, "arrow-angle-double");
}
.icon-arrow-up:before {
    content: map-get($pure-icons-map, "arrow-up");
}
.icon-bookmark:before {
    content: map-get($pure-icons-map, "bookmark");
}
.icon-calendar:before {
    content: map-get($pure-icons-map, "calendar");
}
.icon-camera:before {
    content: map-get($pure-icons-map, "camera");
}
.icon-chat:before {
    content: map-get($pure-icons-map, "chat");
}
.icon-computer:before {
    content: map-get($pure-icons-map, "computer");
}
.icon-copy:before {
    content: map-get($pure-icons-map, "copy");
}
.icon-cursor:before {
    content: map-get($pure-icons-map, "cursor");
}
.icon-euro:before {
    content: map-get($pure-icons-map, "euro");
}
.icon-globe:before {
    content: map-get($pure-icons-map, "globe");
}
.icon-label:before {
    content: map-get($pure-icons-map, "label");
}
.icon-lock:before {
    content: map-get($pure-icons-map, "lock");
}
.icon-mail:before {
    content: map-get($pure-icons-map, "mail");
}
.icon-picture:before {
    content: map-get($pure-icons-map, "picture");
}
.icon-send:before {
    content: map-get($pure-icons-map, "send");
}
.icon-stop:before {
    content: map-get($pure-icons-map, "stop");
}
.icon-suitcase:before {
    content: map-get($pure-icons-map, "suitcase");
}
.icon-time:before {
    content: map-get($pure-icons-map, "time");
}
.icon-users:before {
    content: map-get($pure-icons-map, "users");
}
.icon-book:before {
    content: map-get($pure-icons-map, "book");
}
.icon-chart:before {
    content: map-get($pure-icons-map, "chart");
}
.icon-save:before {
    content: map-get($pure-icons-map, "save");
}
.icon-info:before {
    content: map-get($pure-icons-map, "info");
}
.icon-star-half:before {
    content: map-get($pure-icons-map, "star-half");
}
.icon-lock_2:before {
    content: map-get($pure-icons-map, "lock_2");
}
.icon-warning:before {
    content: map-get($pure-icons-map, "warning");
}
