
@import '~styles/_var_app.scss';

.page-header{
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;

    > * {
        border-bottom: 1px solid $c-border;
    }

    .header{
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        height: $header-height;
        background-color: white;
        padding: 0px $padding-x * 0.5;

        @media (min-width: $s-tablet) {
            padding: 0px $padding-x;
        }

        .wrapper{
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 10px;
        }

        .left, .right{
            display: flex;
            align-items: center;
            flex: 1;
        }

        .left{
            flex: 0 1 auto;
        }

        .right{
            justify-content: end;

            &::v-deep(.buttons) {
                display: flex;
                gap: 10px;
            }
        }
    }

    .tools{
    }
}

.button-burger{
    margin-right: 15px;
}

