
@import '~styles/_var_app.scss';

#contextual-drawer{
    $pad-x: 50px;

    display: flex;
    flex-direction: column;

    padding: 60px $pad-x 20px $pad-x !important;
    background-color: $c-light;
    min-height: 100%;

    &::v-deep(.buttons-sticky) {
        padding-top: 20px;
    }

    &::v-deep(.stretcher) {
        flex: 1;
        display: flex;
        flex-direction: column;

        .buttons-sticky{
            margin-top: auto;
        }
    }

    &::v-deep(.title) {
        margin-bottom: 6px;
    }

    &::v-deep(.intro) {
        margin-bottom: 16px;
        font-size: 17px;
    }


}
