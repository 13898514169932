
@import '~styles/_var_app.scss';

$gap: 20px;

// TEMP
.page-content{
    display: flex;

    .temp{
        display: none;
        margin-left: 20px;
        font-size: 12px;
        line-height: 0.7;

        pre{
            position: sticky;
            top: 100px;
            max-width: 400px;
            white-space: break-spaces;
        }
    }
}

.section{
    gap: $gap;
    margin-bottom: $gap;
}

.block{
    background-color: #fff;
    padding: 20px;

    @media (min-width: $s-mobile) {
        padding: 30px;
    }
}

.section-summary{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "identity"
                         "price"
                         "status";

    @media (min-width: $s-tablet) {
        grid-template-columns: 1fr 2fr;
        grid-template-areas: "identity price"
                             "identity status";
    }


    .block-identity{
        grid-area: identity;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .picture{
            width: 90px;
            height: 90px;
            margin-bottom: 10px;

            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .tags{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;
            margin-bottom: 10px;
        }

        .name{
            margin-bottom: 10px;
            text-align: center;
        }

        .date{
            font-size: 12px;
        }
    }

    .block-price{
        grid-area: price;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .main{
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
        }

        .accessories{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }

        .price{
            position: relative;
            transition: color $t-duration;

            //&:before {
            //    content: '';
            //    position: absolute;
            //    width: 0%;
            //    height: 2px;
            //    top: 45%;
            //    right: 0;
            //    background-color: $c-gray;
            //    transition: width $t-duration;
            //}

            &.need-refresh {
                color: transparentize($c-gray, 0.5);

                //&:before {
                //    width: 100%;
                //}
            }
        }

        .notes{

        }

    }

    .block-status{
        grid-area: status;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title{
            margin-bottom: 12px;
        }

        .status{

        }

    }
}

.section-comments{
    display: flex;
    flex-wrap: wrap;

    @media (min-width: $s-tablet) {
        flex-wrap: nowrap;
    }

    .block-comment{
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title{
            margin-bottom: 10px;
        }

        .comment{
        }
    }

    .block-call{
        flex: 1 1 auto;
        display: flex;
        flex-direction: revert;
        justify-content: space-between;
        align-items: center;
        gap: 10px 20px;
        flex-wrap: wrap;

        @media (min-width: $s-tablet) {
            flex: 1 1 20%;
        }

        .title{
        }

        .time, .phone{
            font-size: 16px;
            font-weight: bold;

            &:not(:last-child):not(:only-child){
                margin-bottom: 20px;
            }

            .v-icon{
                font-size: 17px;
                margin-right: 8px;
            }
        }

        .phone{
            display: flex;
            align-items: center;
        }
    }
}

.section-tabs{

    .v-tabs {
        --v-tabs-height: 60px;

        @media (min-width: $s-mobile) {
            --v-tabs-height: 70px;
        }
    }

    .v-tab{
        font-size: 13px;

        &.v-tab:not(:last-child){
            border: none;
        }

        &.v-tab--selected{
            background-color: #fff;

            .v-btn__overlay{
                display: none;
            }
        }

    }

    .v-window-item{
        background-color: #fff;
        padding: 20px 20px;

        @media (min-width: $s-mobile) {
            padding: 30px 40px;
        }
    }

    .title{
        display: block;
        margin-bottom: 20px;
    }

    .subtitle{
        margin-bottom: 2px;
    }

    .heading{
        display: flex;
        flex-direction: column;
        gap: 0 20px;
        flex-wrap: wrap;

        @media (min-width: $s-mobile-sm) {
            flex-direction: row;
        }
        
        .title{
            flex: 1 0 auto;
        }

        .tools{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 10px;

            @media (min-width: $s-mobile-sm) {
                margin-left: auto;
            }
            
            .button:not(.v-btn--icon) {
                flex: 1 0 auto;
            }
        }
    }

}

.product-category{
    margin-top: 20px;
    margin-bottom: 30px;

    .heading{
        margin-bottom: 10px;
    }

    .products{
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(1, 1fr);

        @media (min-width: $s-tablet) {
            grid-template-columns: repeat(2, 1fr);
        }

        .product{
            padding: 15px 15px 15px 0px;
            border: 1px solid $c-border;
            background-color: $c-gray-light;
            display: flex;
            align-items: center;
            border-radius: 10px;
            transition: border $t-duration * 0.5 ease, background-color $t-duration * 0.5 ease;

            @media (min-width: $s-mobile) {
                padding: 15px 20px 15px 10px;
            }

            &::v-deep(.v-label) {
                width: 100%;
            }

            &::v-deep(.v-input__control){
                width: 100%;
            }

            &::v-deep(.v-selection-control) {
                width: 100%;

                .v-icon{
                    font-size: 16px;
                }
            }

            &::v-deep(.v-selection-control__wrapper) {
                align-self: flex-start;
                margin-top: 8px;
            }

            &:hover{
                border-color: $c-third;
            }

            &.active{
                border-color: $c-third;
                background-color: transparentize($c-third, 0.85);

                &::v-deep(.v-selection-control) {
                    .v-icon{
                        color: $c-third;
                    }
                }
            }

            .wrapper{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px 0;
                width: 100%;
            }

            .picture-and-infos{
                display: flex;
                gap: 10px 0;
            }

            .picture{
                flex: none;
                width: 50px;
                height: 50px;
                border-radius: 10px;
                border: solid 1px $c-border;
                background-color: white;
                overflow: hidden;
                margin-right: 20px;
                margin-left: 5px;

                > img{
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }

            }
            .name{
                font-weight: bold;
                margin-right: 10px;
            }
            .material{
                text-transform: uppercase;
                font-weight: 300;
                font-size: 12px;
                margin-right: 10px;
            }
            .price{
                margin-left: auto;
                font-weight: bold;
                flex-wrap: wrap;
                gap: 10px 0;
            }

            .buttons{
                display: flex;
                align-items: center;
                margin-left: 10px;
                gap: 10px;
            }

        }
    }
}

.steps{
    .step{
        padding-top: 10px;

        &:not(:last-child):not(:only-child){
            padding-bottom: 20px;
            border-bottom: 1px solid $c-border;
        }

        .col{
            display: flex;
            align-items: center;
        }

        .name{
            display: flex;
            align-items: flex-start;
            gap: 10px;
            font-size: 18px;
            margin-bottom: 10px;

            i{
                font-size: 20px;
            }
        }

        .place{
            font-weight: bold;
        }
    }
}

.cities{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 10px;

    .city{

    }
}

.catchphrases{
    display: flex;
    flex-direction: column;
    gap: 10px;

    .catchphrase{

    }
}

.death-notice-preview{
    font-family: Verdana, Arial, sans-serif;
    max-width: 290px;
    padding: 30px;
    font-size: 16px;
    line-height: 1.25;
    border: 1px solid #101514;
    white-space: pre-line; // To preserve new lines
    margin-top: 16px;
}

