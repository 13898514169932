@use "sass:math";

// We can override the theme like this

//.v-theme--funix {
//    --v-theme-success: 255,0,0;
//}


// Here we can override component styles
.v-container{
    padding: 0px;
}

.v-overlay{
    &__scrim{
        background-color: transparent;
    }
}

.v-row{
    $gap-y: 4px;
    $gap-x: 10px;

    margin: #{-$gap-y} #{-$gap-x};

    [class*=v-col-],
    &.padded
    {
        padding: $gap-y $gap-x;
    }

    &.v-row--no-gutters{
        margin: 0;

        [class*=v-col-]{
            padding: $gap-y 0;

            // Reapply default spacing for deeper columns
            [class*=v-col-]{
                padding: $gap-y $gap-x;
            }
        }
    }
}


.v-navigation-drawer{
    &--temporary{
        box-shadow: none;
    }

    &--right{
        $width: 450px;
        $offset: 30px;

        left: $offset;
        width: calc(100% - $offset) !important;

        @media (min-width: $width + $offset) {
            left: inherit;
            width: $width !important;
        }
    }

    .close-button{
        $size: 70px;
        border-radius: 100px;
        position: absolute;
        top: calc($size / 2);
        left: calc(-#{$size} / 2);
        width: $size;
        height: $size;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10000;

        .curved-tab{
            position: absolute;
            width: 58px;
            aspect-ratio: 1/2;
            right: 21px;
            background-color: $c-light; // NOTE : we use the background-color + mask technique to change the svg color
            -webkit-mask: url('../../images/curved-tab-small.svg') no-repeat center;
            mask: url('../../images/curved-tab-small.svg') no-repeat center;
        }
    }
}

.v-snackbar{
    &__wrapper{
        background-color: $c-primary;
    }
    &__content{
        text-align: center;
    }
}

.v-tabs{
    .v-btn{
        &.v-slide-group-item--active{
            &:hover{
                .v-btn__overlay{
                    opacity: 0;
                }
            }
        }

        &:hover{
            .v-btn__overlay{
                opacity: 0;
            }
        }
    }

    .v-tab{

        &:after{
            opacity: 1;
            border-radius: 0px;
            border: none;
            border-bottom: 2px solid transparent;
            transition: border $t-duration;
        }

        &--selected{
            &:after{
                border-bottom: 2px solid $c-primary;
            }
        }

    }

    &.mobile-stacked{
        @media (max-width: $s-mobile - 1) {
            height: auto !important;

            .v-slide-group__content{
                flex-wrap: wrap;
                width: 100%;
            }

            .v-tab{
                padding: 0;
            }
        }
    }
}

.v-tab{
    &__slider{
        background: $c-primary !important;
    }

    &.v-tab:not(:last-child) {
        border-right: 1px solid $c-border;
    }
}

.v-list-item{
    .v-list-item-title{
        font-size: 16px;
        opacity: 0.3;
        font-weight: bold;
        transition: color $t-duration, opacity $t-duration;
    }

    .v-list-item__prepend{
        i{
            opacity: 0.3;
            transition: color $t-duration, opacity $t-duration;
            font-size: 16px;
            margin-inline-end: 20px;
        }
    }

    &--active{

        .v-list-item-title{
            opacity: 1;
        }
    }

    &:hover:not(.v-list-item--active){

        .v-list-item-title{
            opacity: 1;
        }

        i{
            opacity: 1;
        }
    }

    .v-list-item__overlay{
        display: none;
    }

    .v-list--nav &{
        &:hover:not(.v-list-item--active){

            .v-list-item-title{
                color: $c-primary;
            }

            i{
                color: $c-primary;
            }
        }
    }
}

.v-icon{
    display: flex !important;
}

.v-btn{

    font-weight: bold;
    letter-spacing: 0;
    text-transform: initial;
    font-size: $btn-text-font-s;
    font-family: $btn-text-font-f;
    line-height: 1;

    &.button{

        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: auto;
        width: auto;
        min-height: $btn-min-h + 8px;
        padding: $btn-pad-h $btn-pad-w;
        border: none;
        border-radius: $btn-border-radius;
        text-decoration: none;
        background-color: $btn-bg-color;
        color: $btn-text-color;
        transition-property: background-color, border-color, color, box-shadow;
        transition-duration: $t-duration;

        box-shadow: 1.5px 1.5px 5px 0 rgba(#000, 0.1), // Bottom right external shadow
        inset 0px 0px 0px 0 transparent, // Top left inner shadow
        inset 0px 0px 0px 0 transparent; // Bottom right inner highlight

        .v-icon{
            font-size: 20px;
        }

        &:hover {
            background-color: $btn-bg-color-hover;
            border-color: $btn-border-color-hover;
            color: $btn-text-color-hover;
            text-decoration: none;
            box-shadow: 0px 0px 0px 0 transparent, // Bottom right external shadow
            inset 4px 4px 6px 0 rgba(73, 53, 37, 0.2), // Top left inner shadow
            inset -2px -2px 6px 0 rgba(#fff, 0.5); // Bottom right inner highlight
        }

        &.button-secondary {
            background: $btn-bg-color-secondary;
            color: $btn-text-color-secondary;
            box-shadow: 0px 0px 0px 0 transparent, // Bottom right external shadow
            inset 0px 0px 0px 0 transparent, // Top left inner shadow
            inset 0px 0px 0px 0 transparent; // Bottom right inner highlight

            &:hover {
                background-color: $btn-bg-color-secondary;
                color: $btn-text-color-secondary;
                box-shadow: 0px 0px 0px 0 transparent, // Bottom right external shadow
                inset 4px 4px 6px 0 rgba(190, 137, 95, 0.2), // Top left inner shadow
                inset -2px -2px 6px 0 rgba(255, 255, 255, 0.5); // Bottom right inner highlight
            }
        }

        &.button-alt {
            background: $c-alt-primary;
            color: $c-white;
            box-shadow: 0px 0px 0px 0 transparent, // Bottom right external shadow
            inset 0px 0px 0px 0 transparent, // Top left inner shadow
            inset 0px 0px 0px 0 transparent; // Bottom right inner highlight

            &:hover {
                background-color: $c-alt-primary;
                color: $c-white;
                box-shadow: 0px 0px 0px 0 transparent, // Bottom right external shadow
                inset 4px 4px 6px 0 #87bcb3, // Top left inner shadow
                inset -2px -2px 6px 0 rgba(255, 255, 255, 0.5); // Bottom right inner highlight
            }
        }

        &.button-secondary-alt {
            background: $c-alt-second-lighter;
            color: $c-alt-primary-lighter;
            box-shadow: 0px 0px 0px 0 transparent, // Bottom right external shadow
            inset 0px 0px 0px 0 transparent, // Top left inner shadow
            inset 0px 0px 0px 0 transparent; // Bottom right inner highlight

            &:hover {
                background-color: $c-alt-second-lighter;
                color: $c-alt-primary-lighter;
                box-shadow: 0px 0px 0px 0 transparent, // Bottom right external shadow
                inset 4px 4px 6px 0 rgba(154, 210, 201, 0.3), // Top left inner shadow
                inset -2px -2px 6px 0 rgba(255, 255, 255, 0.7); // Bottom right inner highlight
            }
        }

        &.button-neutral {
            background: $c-gray-light;
            color: $c-text;
            box-shadow: 1.5px 1.5px 5px 0 rgba(#000, 0.1), // Bottom right external shadow
            inset 0px 0px 0px 0 transparent, // Top left inner shadow
            inset 0px 0px 0px 0 transparent; // Bottom right inner highlight

            &:hover {
                background-color: $c-gray-light;
                color: $c-text;
                box-shadow: 0px 0px 0px 0 transparent, // Bottom right external shadow
                inset 4px 4px 6px 0 rgba(174, 174, 192, 0.4), // Top left inner shadow
                inset -2px -2px 6px 0 rgba(255, 255, 255, 0.7); // Bottom right inner highlight
            }
        }

        &.button-small{
            padding: math.round($btn-pad-h * 0.857) math.round($btn-pad-w * 0.667);
            font-size: math.round($btn-text-font-s * 0.875);
            border-radius: math.round($btn-border-radius * 0.833);
            min-height: $btn-min-h;

            .v-icon{
                font-size: 16px;
            }
        }

        &.button-round{
            border-radius: 100px;
        }

        &.button-invisible{
            background-color: transparent;
            box-shadow: none;
            color: #ccc;

            &:hover {
                color: $c-primary;
            }
        }

        &.no-icon-desktop{

            @media (min-width: $s-mobile-md) {

                .v-btn__prepend,
                .v-btn__append{
                    display: none;
                    margin-inline-start: 0;
                    margin-inline-end: 0;
                }

            }
        }

        &.no-text-mobile{

            padding-left: 14px;
            padding-right: 14px;
            min-width: auto;

            .v-btn__content{
                display: none;
            }

            .v-btn__prepend,
            .v-btn__append{
                margin-inline-start: 0;
                margin-inline-end: 0;
            }

            @media (min-width: $s-mobile-md) {
                .v-btn__prepend {
                    margin-inline-end: calc(var(--v-btn-height) / 4.5);
                }
                .v-btn__append {
                    margin-inline-start: calc(var(--v-btn-height) / 4.5);
                }

                .v-btn__content{
                    display: flex;
                }
            }
        }

        &.block{
            width: 100%;
            margin-bottom: 10px;
        }

        &:disabled,
        &.disabled,
        &[disabled]{
            pointer-events: none;
            background: $btn-bg-color-disabled;
            color: $btn-text-color-disabled;
            box-shadow: none;
        }

        &.v-btn--icon {
            padding: $btn-pad-h;

            &.button-small{
                padding: math.round($btn-pad-h * 0.857);
            }
        }

        .v-btn__overlay{
            display: none;
        }

        .v-btn__content{
            white-space: inherit;
        }
    }

    &.link{
        height: auto;
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: $link-text-color;
        transition: color $t-duration;
        padding-left: 4px;
        padding-right: 4px;

        &:hover{
            color: $link-text-color-hover;
        }

        .v-btn__prepend, .v-btn__append{
            //position: relative;
            //bottom: -2px;

            i{
                font-size: 18px;
            }
        }

        .v-btn__overlay{
            display: none;
        }

        .v-btn__content{
            white-space: normal;

            @media (min-width: $s-tablet) {
                white-space: nowrap;
            }
        }
    }

}

.v-pagination{

    .v-pagination__list{
        flex-wrap: wrap;
    }

    .v-btn{
        border: none;
        border-radius: $btn-border-radius;
        text-decoration: none;
        background-color: $c-gray-light;
        color: $c-text;
        transition-property: background-color, border-color, color, box-shadow;
        transition-duration: $t-duration;
        box-shadow: 1.5px 1.5px 5px 0 rgba(#000, 0.1), // Bottom right external shadow
        inset 0px 0px 0px 0 transparent, // Top left inner shadow
        inset 0px 0px 0px 0 transparent; // Bottom right inner highlight

        &:hover {
            background-color: $c-gray-light;
            color: $c-text;
            box-shadow: 0px 0px 0px 0 transparent, // Bottom right external shadow
            inset 4px 4px 6px 0 rgba(174, 174, 192, 0.4), // Top left inner shadow
            inset -2px -2px 6px 0 rgba(255, 255, 255, 0.7); // Bottom right inner highlight

            .v-btn__overlay{
                opacity: 0;
            }
        }

        &:disabled,
        &.disabled,
        &[disabled]{
            pointer-events: none;
            background: $btn-bg-color-disabled;
            color: $btn-text-color-disabled;
            box-shadow: none;

            .v-btn__overlay{
                opacity: 0;
            }
        }

        .v-icon{
            font-size: 16px;
        }

    }

    &__prev,
    &__next{
        .v-btn{

            .v-btn__content{
                gap: 0 8px;
            }

            &:not(.mobile){
                width: auto;
                padding-left: 20px;
                padding-right: 20px;
            }

            &.mobile{
                .v-btn__content{
                    > span{
                        display: none;
                    }
                }
            }
        }

    }

    &__item{
        &--is-active{

            .v-btn{

                background-color: $c-gray-light;
                color: $c-text;
                box-shadow: 0px 0px 0px 0 transparent, // Bottom right external shadow
                inset 4px 4px 6px 0 rgba(174, 174, 192, 0.4), // Top left inner shadow
                inset -2px -2px 6px 0 rgba(255, 255, 255, 0.7); // Bottom right inner highlight
            }

            .v-btn__overlay{
                opacity: 0;
            }
        }
    }
}


.v-label{
    white-space: inherit;

    // All label that are not inside a field
    &:not(.v-field-label):not(.v-label--clickable) {
        opacity: 1;
        font-weight: bold;
        font-size: 11px;
        margin-bottom: 7px;
        text-transform: uppercase;
    }

    // Checkbox, radio, switches labels
    &.v-label--clickable {
        opacity: 1;
    }
}
.v-field{
    align-items: center;
    background-color: $form-field-bg-color;
    border: 1px solid $form-field-bg-color;
    border-radius: $form-field-border-radius;
    box-shadow: inset 3.3px 3.3px 2.2px 0 rgba(174, 174, 192, 0.2),
    inset -2.2px -2.2px 2.2px 0 rgba(255, 255, 255, 0.7),
    -1px -1px 3px 0 #fff,
    1.5px 1.5px 3px 0 rgba(174, 174, 192, 0.2);

    &.v-field--disabled {
        opacity: 0.6;
    }

    &.v-field--prepended{
        input{
            padding: 12px 12px;
        }

        .v-field__prepend-inner{
            align-items: center;
            padding-top: 0;
            padding-left: 4px;
        }

        .v-icon{
            margin-bottom: -2px;
            opacity: 1;
            font-size: 16px;
        }

        .v-label{
            margin-inline-start: 12px;
        }
    }


    .v-field__field{
        align-items: center;
    }

    .v-field__append-inner{
        padding-top: 0;
        margin-right: 6px;
        display: flex;
        align-items: center;

        .v-icon{
            top: 1px;
            font-size: 24px;
            opacity: 1;
        }
    }

    .v-field__clearable{
        align-items: center;
        padding-top: 0;

        .v-icon{
            margin-bottom: -2px;
            font-size: 16px;
            opacity: 1;
        }
    }

    .v-label{
        opacity: 0.3;
    }

    .v-messages{
        opacity: 1;
    }

    &--active .v-label{
        visibility: inherit;
    }

    .v-field__input{
        padding-left: 20px;
        padding-right: 20px;
        opacity: 1;
    }

    &.v-field--error{
        border: 1px solid $c-primary;
    }
}

.v-text-field{
    &__prefix,
    &__suffix{
        opacity: 1;
        color: $c-text;
        font-weight: bold;
    }

    &--suffixed.price{
        .v-field__input{

            padding-right: 6px;
            text-align: right;
        }

        .v-text-field__suffix{
            padding-right: 20px;
        }
    }
}

.v-textarea{
    &.with-button{
        position: relative;

        .v-field__field{
            padding-bottom: 40px
        }

        .v-input__append{
            margin-inline-start: 0;
        }

        .v-btn{
            position: absolute;
            bottom: 8px;
            left: 50%;
            transform: translateX(-50%);
            min-height: auto;
            min-width: auto;
            width: calc(100% - 2 * 10px);

            .v-btn__content{
                white-space: inherit;
            }
        }
    }
}

.v-autocomplete{
    .v-field{
        overflow: hidden;

        .v-field__input{
            gap: 3px 5px;
        }
    }

    .v-progress-linear{
        &__background {
            opacity: 0;
        }

        &__indeterminate{
            .long, .short{
                background-color: $c-primary;
            }
        }
    }

    &.multiple{

        // NOTE: On multiple autocomplete fields, we use the first item as a fake placeholder
        .v-autocomplete__selection{
            opacity: 0.3;

            &:not(:first-child) {
                display: none;
            }
        }

        // Hide the placeholder when the menu is opened
        &.v-autocomplete--active-menu{
            .v-autocomplete__selection{
                display: none;
            }
        }

        // Hide the placeholder when the field is focused
        .v-field--focused{
            .v-autocomplete__selection{
                display: none;
            }
        }

    }
}

.v-select{

    .v-progress-linear{
        &__background {
            opacity: 0;
        }

        &__indeterminate{
            .long, .short{
                background-color: $c-primary;
            }
        }
    }


    &__content{
        .v-divider{
            background: transparent;
            opacity: 1;
            border-top: 1px dashed $c-border;
            margin-top: 2px;
            margin-bottom: 3px;
        }

        .v-list-item:not(.v-list-item--active) .v-list-item-title{
            opacity: 0.5;
        }
    }

}

.v-switch{

    .v-switch__track{
        box-shadow: inset 2.9px 2.9px 1.9px 0 rgba(174, 174, 192, 0.2), inset -1.9px -1.9px 1.9px 0 rgba(255, 255, 255, 0.7), -0.9px -0.9px 2.6px 0 #fff, 1.3px 1.3px 2.6px 0 rgba(174, 174, 192, 0.2);
        background-color: #f4f4f4;
        transition: background $t-duration, box-shadow $t-duration;
        opacity: 1;
    }

    .v-switch__thumb{
        color: $c-white;
        box-shadow: 1.3px 1.3px 4.4px 0 rgba(174, 174, 192, 0.4), -0.9px -0.9px 2.6px 0 #fff, inset 0 0 0 0.2px rgba(255, 255, 255, 0.2);
        transition: box-shadow $t-duration;
    }

    .v-selection-control--dirty{

        .v-switch__track{
            box-shadow: inset 1.5px 1.5px 1.5px 0 rgba(135, 188, 179, 0.8), inset -1.5px -1.5px 1.5px 0 rgba(255, 255, 255, 0.2);
            background-color: $c-alt-primary;
        }
        .v-switch__thumb{
            box-shadow: 1.1px 1.1px 3.8px 0 rgba(174, 174, 192, 0.4), -0.8px -0.8px 2.3px 0 rgba(255, 255, 255, 0.3), inset 0 0 0 0.2px rgba(255, 255, 255, 0.2);
        }
    }
}

.input-upload{
    .v-input__prepend{
        margin-inline-end: 8px;
    }
    .v-input__append{
        margin-inline-start: 8px;
    }

    .v-input__prepend, .v-input__append{
        padding-top: 0;

        .v-img{
            border: 1px solid transparent;
            border-radius: 8px;
        }

    }
}

// Add an asterisk on required fields
.field.required {
    .v-label:not(.v-field-label):not(.v-label--clickable){
        &:after {
            content: "*";
            position: relative;
            top: -2px;
            margin-left: 3px;
        }
    }
}

.field.important {
    .v-field{
        border-color: $c-primary;
    }
}

.v-chip-group{
    padding: 0;
    gap: 5px 0;
}

.v-chip{
    --v-chip-height: 28px !important;
    font-size: 13px !important;
    padding: 0 8px !important;
    line-height: 1;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border: 1px solid $c-primary;
    color: $c-primary;
    font-weight: bold;
    background-color: #fff;
    transition: color $t-duration, background-color $t-duration;

    @media (min-width: $s-mobile) {
        --v-chip-height: 32px !important;
        font-size: 14px !important;
        padding: 0 12px !important;
    }

    .v-chip__filter{
        display: none;
    }
    .v-chip__overlay{
        display: none;
    }
    .v-chip__underlay{
        display: none;
    }

    .v-chip__close{
        margin-inline-start: 3px;
        .v-icon{
            font-size: 12px;
        }
    }

    &:hover{
        background-color: $c-second-lighter;
    }

    &.v-chip--selected{
        background-color: $c-primary;
        color: #fff;
    }

    &.small{
        font-size: 12px !important;
        height: 28px;
        padding: 0.9em;
    }
}

.v-card{
    padding: 25px 30px;
    border-radius: 15px;
    box-shadow:
            1.5px 1.5px 5px 0 rgba(174, 174, 192, 0.4),
            -1px -1px 3px 0 #fff,
            inset 0 0 0 0.2px rgba(255, 255, 255, 0.2),
            inset 0 0 0 0 transparent;

    &:not(
        [class$="-enter-from"],
        [class$="-enter-active"],
        [class$="-enter-to"],
        [class$="-leave-from"],
        [class$="-leave-active"],
        [class$="-leave-to"]
    ){
        transition: box-shadow $t-duration;
    }

    &:hover{
        box-shadow:
                0px 0px 0px 0 transparent,
                0px 0px 0px 0 transparent,
                inset 4px 4px 8px 0 rgba(0, 0, 0, 0.1), // Top left inner shadow
                inset -2px -2px 8px 0 rgba(#fff, 0.5); // Bottom right inner highlight
    }

    .v-card__overlay{
        display: none;
    }

}

.v-dialog {

    .v-overlay__content{

        .v-card {
            text-align: center;
            padding: 54px 40px 30px 40px;
            border-radius: 15px;

            .v-card-title{
                padding: 0;
                margin-bottom: 14px;
            }
            .v-card-text{
                padding: 0;
                margin-bottom: 30px;
            }
            .v-card-actions{
                padding: 0;
                justify-content: center;
            }

            .button-close{
                margin-right: 8px;
                margin-top: 8px;
                color: $c-text;

                &:hover{
                    color: $c-primary;
                }

            }
        }
    }
}