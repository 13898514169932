

.button-close{
    position: absolute;
    top: 0;
    right: 0;
}

.input{
    margin: 0 auto;

    &::v-deep(input) {
        text-align: center;
        font-size: 22px;
        padding: 8px;
    }

    &.small{
        width: 100px;
    }
}
