
@import '~styles/_var_app.scss';

.page-header {
    top: $header-height + 1px; // Override the sticky position

    &.additional-offset{
        top: $header-height + $header-height + 1px;
    }
}

::v-deep(.form){
    padding-top: 20px;
}

.service-offering-categories{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin-top: 20px;

    @media (min-width: $s-mobile-md) {
        grid-template-columns: repeat(2, 1fr);
    }

    .service-offering-category{
        background-color: #ffffff;
        padding: 30px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .service-offering-category-icon{
            width: 100px;
            height: 100px;
            margin-bottom: 20px;

            img{
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        > .name{
            margin-bottom: 20px;
        }

        .service-offerings{
            width: 100%;

            .service-offering{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                &:not(:last-child):not(:only-child){
                    border-bottom: 1px solid $c-border;
                }

                > .name {

                }

                &::v-deep(.v-selection-control){
                    justify-content: flex-end;
                }
            }
        }
    }
}

.title{
    margin-bottom: 8px;
}
