

.establishment{
    width: 100%;
    background-color: #ffffff;
    padding: 30px;
    margin-top: 20px;
    margin-bottom: 30px;

    .name{
        font-weight: bold;
    }

    .address{

    }
}

.service-pricing-categories{
    width: 100%;
}

.service-pricing-category{

    &:not(:last-child){
        margin-bottom: 30px;
    }

    > .name{
        margin-bottom: 10px;
    }

    .service-pricing{
        margin-bottom: 10px;

        > .name{
            margin-bottom: 10px;
        }
    }
}

.buttons{
    margin-top: 20px;
}

